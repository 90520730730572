import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-110adc25"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "card-img-box"
};
const _hoisted_2 = {
  key: 0,
  class: "flag"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_loading = _resolveComponent("van-loading");
  const _component_van_image = _resolveComponent("van-image");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(['img-box', 'border', {
      'gray': $props.gray
    }]),
    ref: "imgBox"
  }, [_createVNode(_component_van_image, {
    "lazy-load": "",
    class: "img",
    src: $props.src
  }, {
    loading: _withCtx(() => [_createVNode(_component_van_loading, {
      type: "spinner",
      size: "20"
    })]),
    _: 1
  }, 8, ["src"]), $props.have_back_flag ? (_openBlock(), _createElementBlock("div", _hoisted_2, "多 图")) : _createCommentVNode("", true)], 2)]);
}