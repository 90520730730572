import { PAGInit } from 'libpag';
export default {
  props: {
    src: {
      type: String
    },
    gray: {
      type: Boolean,
      default: false
    },
    showPag: {
      type: Boolean,
      default: false
    },
    have_back_flag: {
      // 是否是双面卡
      type: Boolean,
      default: false
    }
  },
  async mounted() {
    if (this.showPag) {
      const PAG = await PAGInit();
      const buffer = await fetch('https://p6.moimg.net/md/image/card_02.pag').then(response => response.arrayBuffer());
      const pagFile = await PAG.PAGFile.load(buffer);
      const canvas = this.$refs.canvas;
      const dom = this.$refs.imgBox;
      const width = dom.offsetWidth;
      canvas.style.width = width + 'px';
      canvas.style.height = width / pagFile.width() * pagFile.height() + 'px';
      const pagView = await PAG.PAGView.init(pagFile, canvas, {
        useCanvas2D: true
      });
      pagView.setRepeatCount(0);
      await pagView.play();
    }
  }
};